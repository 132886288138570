
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { JustifiPaymentsList, JustifiPaymentDetails} from '@justifi/react-components';
import { justifiAuthTokenService } from '../../services/userServices';
import { Modal } from 'react-bootstrap';



const PaymentsList = props => {

    const [access_token, set_access_token] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [activeId, setActiveId] = useState('');


    const handlePaymentRowClicked = data => {
        setShowModal(true);
        setActiveId(data.detail.id);
    }


    const handleCloseModal = () => {
        setShowModal(false);
        setActiveId('');
    }


  useEffect(() => {
    if(props.subAccountId){
        let params = {subAccountId: props.subAccountId}
        justifiAuthTokenService(params).then(res => {
            if(res?.data?.statusCode){
                set_access_token(res.data.responseData.access_token)
            }
        })
    }
    
  }, [props.subAccountId]);
    

    return (
        <div className='coupon_table_wrapper px-0'>
                {
                    access_token ? (
                        <JustifiPaymentsList
                            onPayment-row-clicked={handlePaymentRowClicked}
                            data-testid="justifi-payments-list"
                            auth-token={access_token}
                            account-id={props.subAccountId} />
                    ) : ''
                }


                <Modal
                    show={showModal}
                    animation={false}
                    dialogClassName="payment_modal"
                    size="lg"
                    centered
                >
                    <Modal.Body>
                        <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>

                        {
                            activeId ? (
                                <JustifiPaymentDetails
                                    data-testid="justifi-payment-details"
                                    auth-token={access_token}
                                    payment-id={activeId} />
                            ) : ''
                        }
                        
                    </Modal.Body>
                </Modal>

            
        </div>
    )

}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { campCode, subAccountId } = userProfile;

    return {
        campCode,
        subAccountId
    };
}


export default connect(mapStateToProps)(PaymentsList);
