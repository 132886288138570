import React, { useEffect, useRef } from "react";

 const InsuranceComponent = React.memo((props) => {
    const {children, ...otherProps} = props;

    const ref = useRef();
    useEffect(() => {
        if (ref && ref.current) {
            const currentRef = ref.current;
            if (props.offerStateChange) {
                currentRef.addEventListener("offer-state-change", props.offerStateChange);
                return function cleanup() {
                    currentRef.removeEventListener("offer-state-change", props.offerStateChange);
                };
            }
        }
    }, [props.offerStateChange]);

    return (
        // <InsuranceComponentMemo {...props} ref={ref}/>
        <multi-offer {...otherProps} ref={ref}>
            {children}
        </multi-offer>
    )
})
export default InsuranceComponent