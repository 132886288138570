import React, { useEffect, useState, Fragment } from 'react';
import Slider from 'react-slick';
import Select from 'react-select';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { getCamperAge, getHtml, getgendersdisplay, shortsAndTshirtSizes, gradeArr, resHandle } from '../../../helper';
import { addProductWithProgramService, getProductsService, removeItemFromProgramCart } from '../../../services/onlineStoreService';
import { getSingleEditCamperService, singleEditCamperService, } from '../../../services/campersServices';
import { connect } from 'react-redux';
import { PDFIcon } from '../../reuse/SVG';
import WaiverPopups from './WaiverPopups';
import { notification } from 'antd';
import Addon from '../../online-store/Addon';
import QuestionsComponent from '../../common/Questions/QuestionsComponent';
import PdfViewer from '../../common/PdfViewer/PdfViewer';
import LacroseCheck from '../../Lacrose/LacroseCheck';
import { uslMemSecCheck } from '../../Lacrose/helper';
import ConfirmDetails from '../confirmDetails';



const getSizes = arr => {
    let filterArr = [];
    if (arr?.length) {
        let isHasSize = arr.filter(i => (i.name !== 'No Size'));
        if (isHasSize.length) {
            let onlyName = isHasSize.map(item => item.name);
            let uniqArr = onlyName.filter((item, index) => onlyName.indexOf(item) === index);
            filterArr = uniqArr.map(i => ({ value: i, label: i }))
        }
    }
    return filterArr;
}




const getColors2 = (arr, val) => {
    let filterArr = [];

    if (val) {
        if (arr?.length) {
            let isHasColor = arr.filter(i => i.name === val);
            let isHasColor2 = isHasColor.filter(i => i.color !== 'No Color');
            if (isHasColor2.length) {
                let onlyName = isHasColor2.map(item => item.color);
                let uniqArr = onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({ value: i, label: i }))
            }
        }
    } else {
        if (arr?.length) {
            let isHasColor = arr.filter(i => i.color !== 'No Color');
            if (isHasColor.length) {
                let onlyName = isHasColor.map(item => item.color);
                let uniqArr = onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({ value: i, label: i }))
            }
        }
    }
    return filterArr;
}





const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};



const CurrentParticipentComponent = (props) => {

    let { currentCamper, cartProgramData, closeSelectingView } = props;


    const [campRelatedProducts, setCampRelatedProducts] = useState([]);
    const [campRelatedProductCount, setCampRelatedProductCount] = useState(0);

    const [additionalExperience, setAdditionalExperience] = useState([]);
    const [questions, setQuestions] = useState([]);

    // const [groupCode, setGroupCode] = useState('');
    const [USLMemberIDErr, setUSLMemberIDErr] = useState('');
    const [USLResponseSuccess, setUSLResponseSuccess] = useState('');
    const [viewProduct, setViewProduct] = useState({});

    const [concussion, setConcussion] = useState(false);
    const [concussionErr, setConcussionErr] = useState('');
    const [keyDeposit, setKeyDeposit] = useState(false);
    const [keyDepositErr, setKeyDepositErr] = useState('');
    const [waiverURL, setWaiverURL] = useState('');
    const [waiverURLError, setWaiverURLError] = useState('');
    const [waiver2URL, setWaiver2URL] = useState('');
    const [waiver3URL, setWaiver3URL] = useState('');
    const [waiver4URL, setWaiver4URL] = useState('');
    const [waiverType, setWaiverType] = useState('');
    const [waiverModal, setWaiverModal] = useState(false);
    const [activeForm, setActiveForm] = useState({});

    const [updatesSizeModal, setUpdatesSizeModal] = useState(false);
    const [shortsSize, setShortsSize] = useState('');
    const [tshirtSize, setTshirtSize] = useState('');
    const [currentGrade, setCurrentGrade] = useState('');
    const [isCommuter, setIsCommuter] = useState(false);
    const [addons, setAddons] = useState([]);
    const [camplistid, setcamplistid] = useState('');
    const [camplistidErr, setcamplistidErr] = useState('');


    const [alertModal, setAlertModal] = useState(false);

    useEffect(() => {
        if (cartProgramData?.additionalExperience?.length) {
            let _additionalExperience = cartProgramData?.additionalExperience.map(i => ({ ...i, quantity: 0, question: { ...i.question, answer: '' } }))
            setAdditionalExperience(_additionalExperience);
        }

        if (cartProgramData?.questions?.length) {
            let _questions = cartProgramData?.questions.map(i => ({ ...i, answer: '' }))
            setQuestions(_questions);
        }
        getProducts()
        setGradeAndSize()
    }, [])

    const setGradeAndSize = async () => {

        const params = `?_id=${currentCamper._id}`
        const res = await getSingleEditCamperService(params)
        const {status, data: {result} } = resHandle(res);
        if(status){
            setTshirtSize({ label: result.tshirtSize, value: result.tshirtSize });
            setCurrentGrade({ label: result.currentGrade, value: result.currentGrade }); 
        }else{
            const tsize = currentCamper?.tshirtSize
            const grade = currentCamper?.grade
            setTshirtSize({ label: tsize, value: tsize });
            setCurrentGrade({ label: grade, value: grade });
        }
    }


    const updateBookingKey = () => {
        if (activeForm.type === 'keyDeposit') {
            setKeyDeposit(true);
            setKeyDepositErr('');
        } else {
            setConcussion(true);
            setConcussionErr('');
        }
        setActiveForm({})
    }



    const waiverURLAction = (data) => {
        switch (waiverType) {
            case 1: setWaiverURL(data); break;
            case 2: setWaiver2URL(data); break;
            case 3: setWaiver3URL(data); break;
            case 4: setWaiver4URL(data); break;
            default: break;
        }
        setWaiverModal(false);
    }



    const getProducts = () => {
        let pId = cartProgramData.programId;
        let query = `?&page=0&limit=100&itemType=1&type=2&programId=${pId}`;
        getProductsService(query).then(res => {
            if (res?.data?.statusCode) {
                let _campRelatedproducts = {}
                // if(res.data.responseData.result.lenth > 0){
                _campRelatedproducts = res.data.responseData.result.map((item) => {
                    const _matchingItem = props.cartProgramData.associatedCartInfo.length > 0
                        ? props.cartProgramData.associatedCartInfo.filter(aItem => (aItem.items.itemId === item._id && aItem.items.camperId === currentCamper._id))
                        : []
                    if (_matchingItem?.length > 0) {
                        return { ...item, isAdded: true, cartId: _matchingItem[0].cartId }
                    }
                    return item
                })
                setCampRelatedProducts(_campRelatedproducts);
                setCampRelatedProductCount(res.data.responseData.count);
            }
        })
    }





    const handleAdditionalExperienceQuantity = (index, type) => {
        let temp = [...additionalExperience];
        if (typeof temp[index].quantity === 'undefined') {
            temp[index].quantity = 0;
        }
        if (type) {
            temp[index].quantity = (temp[index].quantity || 0) + 1;
        } else {
            if (temp[index].quantity > 0) {
                temp[index].quantity = (temp[index].quantity || 0) - 1;
            }
        }
        setAdditionalExperience([...temp]);
    };



    const handleAdditionalExperienceChecked = index => {
        let temp = [...additionalExperience];
        if (!temp[index].quantity) {
            setAlertModal(true);
        } else {
            temp[index].quantity = 0;
        }
        setAdditionalExperience([...temp]);
    }


    const handleAdditionalExperienceAnsChange = (val, index) => {
        let temp = [...additionalExperience];
        temp[index].question.answer = val;
        temp[index].question.answerErr = '';
        setAdditionalExperience([...temp]);
    }


    const handleAdditionalExperienceAnsCheckboxChange = (val, index) => {
        let temp = [...additionalExperience];
        temp[index].question.answer = val;
        temp[index].question.answerErr = '';
        temp[index].question.isOtherOptionSelected = 0;
        setAdditionalExperience([...temp]);
    }


    const handleIsOtherOptionClick = (index) => {
        let temp = [...additionalExperience];
        temp[index].question.answer = '';
        temp[index].question.answerErr = '';
        temp[index].question.isOtherOptionSelected = temp[index]?.question?.isOtherOptionSelected ? 0 : 1;
        setAdditionalExperience([...temp]);
    }


    const handleCamperSubmit = () => {
        let validate = true;

        let _additionalExperience = [...additionalExperience];
        let has_additionalExperience = _additionalExperience.filter(item => item.quantity);
        if (has_additionalExperience.length) {
            _additionalExperience = _additionalExperience.map(item => {
                const {question, ...rest } = item;
                return {
                    ...rest,
                    answer: {
                        ...question,
                        answerErr: item.quantity ? item?.answer?.isRequred === 1 ? item.answer.answer ? '' : 'This field is required' : '' : ''
                    }
                }
            })

            let has_additionalExperience_err = _additionalExperience.filter(item => item.answer.answerErr);

            if (has_additionalExperience_err.length) {
                let element = document.getElementById(has_additionalExperience_err[0]._id);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
                setAdditionalExperience(_additionalExperience);
                validate = false
            }
        }


        if (cartProgramData?.agegroups?.length) {
            if (camplistid === '') {
                setcamplistidErr('Please select one age group');
                validate = false
            }
        }



        let _questions = [...questions];
        if (questions.length) {
            _questions = _questions.map(item => {
                const _newItem = {
                    ...item,
                    answerErr: (item?.isRequred === 1) ? (item.answer || item?.isSkipped) ? '' : 'This field is required' : ''
                }
                const { isSkipped, ...newItem } = _newItem
                return newItem;
            })
            let has_questions_err = _questions.filter(item => item.answerErr);

            if (has_questions_err.length) {
                let element = document.getElementById(has_questions_err[0]._id);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
                setQuestions(_questions);
                validate = false
            }
        }

        if (cartProgramData?.is_keydrom === 1) {
            if (!isCommuter) {
                if (!keyDeposit) {
                    setKeyDepositErr('Please Agree Key Deposit');
                    validate = false
                }
            }
        }


        if (cartProgramData?.isParentAthlete === 1) {
            if (!concussion) {
                setConcussionErr('Please Agree Parent/Athlete Concussion');
                validate = false
            }
        }

        if (cartProgramData.is_lacrose === 1) {
            validate = uslValidate()
        }

        if(cartProgramData?.is_waivers === 1 && !waiverURL){
            validate = false
            setWaiverURLError('Please Fill Wavier Form.');
        }
        const currentCamperDetails = {
            validate,

        }
        if (validate) {
            let d_additionalExperience = [..._additionalExperience];
            let d_has_additionalExperience = d_additionalExperience.filter(item => item.quantity);
            let _currentCamper = {
                ...currentCamper,
                _id: currentCamper._id,
                camperId: currentCamper._id,
                camperName: `${currentCamper.firstName} ${currentCamper.lastName}`,
                additionalExperience: d_has_additionalExperience,
                answers: questions,
                concussion: concussion,
                keyDeposit: keyDeposit,
                waiver: waiverURL,
                waiverLiability: waiver2URL,
                keyDorm: waiver3URL,
                isParentAthlete: waiver4URL,
                isCommuter: isCommuter,
                camplistid: camplistid
            }
            currentCamperDetails.camperDetails =  _currentCamper;
        }
        return currentCamperDetails;

    }


    const uslValidate = () => {
        let validate = true;
        if (!USLResponseSuccess?.mem_status) {
            setUSLMemberIDErr('* USAL member ID is required.');
            validate = false;

        } else if (USLResponseSuccess?.mem_status !== 'Active') {
            setUSLMemberIDErr('* Cannot proceed without a valid USAL membership.');
            validate = false;
        } else if (uslMemSecCheck(USLResponseSuccess.exp_date, cartProgramData.endDate)) {
            setUSLMemberIDErr('*Cannot proceed without a valid USAL membership.');
            validate = false;
        }
        return validate;
    }

    const handleUSLSuccessResponse = (res) => {
        setUSLMemberIDErr('');
        setUSLResponseSuccess(res);
    }




    const handleValidateProduct = () => {
        let validate = true;
        let _viewProduct = { ...viewProduct };

        if (getColors2(viewProduct.sizes).length) {
            if (!viewProduct.selectColor) {
                _viewProduct.selectColorErr = 'Please Select Color';
                validate = false;
            }
        }

        if (getSizes(viewProduct.sizes).length) {
            if (!viewProduct.selectSize) {
                _viewProduct.selectSizeErr = 'Please Select Size';
                validate = false;
            }
        }

        if (_viewProduct.selectSize) {
            let selectSizeFilterd = _viewProduct.sizes.filter(i => i.name === _viewProduct.selectSize);
            if (selectSizeFilterd.length > 1) {
                let selectColorFilterd = selectSizeFilterd.filter(i => i.color === viewProduct.selectColor);
                if (selectColorFilterd[0].value < _viewProduct.count) {
                    _viewProduct.selectSizeErr = 'This item not available';
                    validate = false;
                }
            } else {
                if (selectSizeFilterd[0].value < _viewProduct.count) {
                    _viewProduct.selectSizeErr = 'This item not available';
                    validate = false;
                }
            }
        }

        setViewProduct(_viewProduct);
        return validate;

    }





    const addProductToCart = () => {
        handleAddtoCartProduct();
    }

    const handleAddtoCartProduct = () => {
        const allProducts = [...campRelatedProducts]
        const index = allProducts.findIndex(item => item._id === viewProduct._id);
        if (handleValidateProduct()) {
            let params = {
                cartId: props.cartId,
                itemId: viewProduct._id,
                color: viewProduct.selectColor || '',
                sizes: viewProduct.selectSize || '',
                count: viewProduct.count,
                price: viewProduct.price,
                title: viewProduct.title,
                programId: cartProgramData.programId,
                camperId: currentCamper._id
            }
            if (addons.length > 0) {
                const _addons = addons.filter(itm => itm.count > 0);
                params.subItems = [..._addons]
            }

            addProductWithProgramService(params).then(res => {
                if (res.data.statusCode) {
                    allProducts[index].isAdded = true;
                    allProducts[index].cartId = res.data.responseData.cartId;
                    setCampRelatedProducts(allProducts);
                    setViewProduct({});
                    setAddons([]);
                    notification.open({
                        description: 'Product added successfully',
                    });
                }
            })
        }

    }



    const handleColorChange = e => {
        let _viewProduct = { ...viewProduct };
        _viewProduct.selectColor = e.value;
        _viewProduct.selectColorErr = '';
        setViewProduct(_viewProduct);

    }


    const handleSizeChange = e => {
        let _viewProduct = { ...viewProduct };
        _viewProduct.selectSize = e.value;
        _viewProduct.selectSizeErr = '';
        _viewProduct.selectColor = '';
        _viewProduct.selectColorErr = '';
        setViewProduct(_viewProduct);

    }


    const handleStoreCount = (type) => {
        let _viewProduct = { ...viewProduct };
        if (type === 'add') {
            _viewProduct.count = _viewProduct.count + 1

        } else {
            if (_viewProduct.count !== 1) {
                _viewProduct.count = _viewProduct.count - 1
            }
        }


        setViewProduct(_viewProduct);


    }


    const handleUpdatesSizeModal = () => {
        setUpdatesSizeModal(true);
        if (tshirtSize === '') {
            setTshirtSize({ label: currentCamper.tshirtSize, value: currentCamper.tshirtSize });
        }
        if (currentGrade === '') {
            setCurrentGrade({ label: currentCamper.grade, value: currentCamper.grade });
        }

    }



    const updateSingleCamper = () => {
        let params = {
            participantId: currentCamper._id,
            tshirtSize: tshirtSize.value,
            shortsSize: shortsSize.value,
            currentGrade: currentGrade.value,
        }
        setUpdatesSizeModal(false);
        singleEditCamperService(params).then(res => {
            if (res?.data?.statusCode) {
                //getCartItemFun();
                notification.open({
                    description: res?.data?.responseData?.message,
                });
            }else{
                setGradeAndSize()
            }
        })
    }



    const getMultipleDatePriceWithCommuter = () => {
        let _totalPrice = 0;
        if(cartProgramData.additionalDates?.length ){
        const _res = cartProgramData.additionalDates.filter(el => cartProgramData.selectedDates.find((id) => el._id === id));
            if (_res.length) {
                //_res.map(i => _totalPrice += (i.commuterPrice || i.standardPrice))
                _res.map(i => _totalPrice += (i.commuterPrice))
            }
        }
        return _totalPrice;
    }

    const getMultipleDatePrice = () => {
        let _totalPrice = 0;
        if(cartProgramData.additionalDates?.length ){
            const _res = cartProgramData.additionalDates.filter(el => cartProgramData.selectedDates.find((id) => el._id === id));
            if (_res.length) {
                _res.map(i => _totalPrice += i.standardPrice)
            }
        }
        return _totalPrice;
    }


    const handleViewProduct = (item) => {
        let filterSizes = item.sizes.filter(i => i.value > 0);
        setViewProduct({ ...item, count: 1, sizes: filterSizes })
    }


    const addAddonsToList = (totalQuantity, addonId, selectedSize, selectedColor,) => {
        if (totalQuantity >= 0) {
            const addonItem = viewProduct?.subItems.filter(item => item.id === addonId)[0]
            const _newAddon = {
                count: totalQuantity,
                color: selectedColor?.value,
                price: addonItem?.price,
                title: addonItem?.title,
                itemId: addonId,
                sizes: selectedSize?.value,
            }
            const _PreviousAddon = addons.filter(item => item.itemId !== addonId);
            setAddons([..._PreviousAddon.filter(obj => Object.keys(obj).length !== 0 || obj.constructor !== Object), _newAddon])
        }

    }

    const handleViewClose = () => {
        setViewProduct({});
        setAddons([]);
    }

    const removeItemFomProgramCart = (parentCartId, item) => {
        const _campRelatedProducts = [...campRelatedProducts];
        const itemIndex = _campRelatedProducts.findIndex(p => p._id === item._id);
        const { isAdded, campId, ...updateditem } = campRelatedProducts[itemIndex];
        const params = `?cartId=${parentCartId}&itemCartId=${item.cartId}`;
        removeItemFromProgramCart(params).then((res) => {
            if (res.data.statusCode) {
                notification.open({
                    description: 'Product Removed successfully',
                });
                _campRelatedProducts[itemIndex] = updateditem;
                setCampRelatedProducts(_campRelatedProducts);
            }
        })
    }

    const getTotalPrice = () => {
        let totalPrice = (viewProduct?.price * viewProduct.count)
        if (addons?.length > 0) {
            totalPrice = addons?.reduce((totalPrice, item) => totalPrice + (item.count * item.price), totalPrice)
        }

        return totalPrice.toFixed(2);
    }

    const handleProductAction = (item, cartId,) => { 
        item.isAdded ? removeItemFomProgramCart(cartId, item) : handleViewProduct(item) 
    }

    return (
        <div className="container p_select_page">

            <div className="row">
                <div className="col-lg-12">

                    <h3>{currentCamper.firstName}</h3>
                    <p>Age: {getCamperAge(currentCamper.dob)} &nbsp; &nbsp; &nbsp; Sex: {getgendersdisplay(currentCamper.gender)}</p>
                    <p>Current Grade: {currentGrade?.value} &nbsp; &nbsp; &nbsp; T-Shirt size: {tshirtSize?.value}</p>
                    <p><b>Would you like to update the T-Shirt size or Grade of this participant? <span className='btn btn-dark btn-sm' onClick={handleUpdatesSizeModal}>Yes</span></b></p>

                    {
                        (getMultipleDatePrice(cartProgramData) && getMultipleDatePriceWithCommuter(cartProgramData)) || (cartProgramData?.campRegistrationFee > 0 && cartProgramData?.commuterPrice > 0) ? <div className='isCommuter_wrapper'>
                            <p><b>Are you staying overnight or day only?</b></p>
                            <p>Overnight campers receive breakfast, lunch, & dinner each day. All overnight campers stay on campus in the dorm rooms and will be roomed with at least one fellow camper their age. They are supervised by our
                                team of counselors who are background checked. Day campers receive lunch & dinner each day. They will NOT receive breakfast. Day campers are also supervised throughout the day by our team of counselors
                                who are background checked.</p>


                            {
                                cartProgramData.byDates === 1 ? <Fragment>

                                    <div className="check_box_wrapper cm_dark_check">
                                        <b className='ms-3'>Overnight (${getMultipleDatePrice(cartProgramData)})</b>
                                        <input
                                            type="checkbox"
                                            checked={!isCommuter}
                                            onChange={() => setIsCommuter(false)}
                                            name="isCommuter" />
                                        <span className="check_box"><span></span></span>
                                    </div>


                                    <div className="check_box_wrapper cm_dark_check">
                                        <b className='ms-3'>Day only (${getMultipleDatePriceWithCommuter(cartProgramData)})</b>
                                        <input
                                            type="checkbox"
                                            checked={isCommuter}
                                            onChange={() => setIsCommuter(true)}
                                            name="isCommuter" />
                                        <span className="check_box"><span></span></span>
                                    </div>

                                </Fragment> : <Fragment>
                                    <div className="check_box_wrapper cm_dark_check">
                                        <b className='ms-3'>Overnight (${cartProgramData?.campRegistrationFee.toFixed(2)})</b>
                                        <input
                                            type="checkbox"
                                            checked={!isCommuter}
                                            onChange={() => setIsCommuter(false)}
                                            name="isCommuter" />
                                        <span className="check_box"><span></span></span>
                                    </div>


                                    <div className="check_box_wrapper cm_dark_check">
                                        <b className='ms-3'>Day only (${(cartProgramData?.commuterPrice + (cartProgramData?.registerNonRefundFee || 0)).toFixed(2)})</b>
                                        <input
                                            type="checkbox"
                                            checked={isCommuter}
                                            onChange={() => setIsCommuter(true)}
                                            name="isCommuter" />
                                        <span className="check_box"><span></span></span>
                                    </div>
                                </Fragment>
                            }

                        </div> : ''
                    }

                    {/* <div className="row">
                        <div className="col-md-6 form-group">
                            <label>Group Code [Optional]</label>
                            <input
                                type="text"
                                className="form-control"
                                value={groupCode}
                                onChange={(e) => setGroupCode(e.target.value)} />
                        </div>
                    </div> */}




                    {
                        cartProgramData?.agegroups?.length ? cartProgramData?.agegroups.filter(aa => aa.spotsavailable).map((ageG, ind) => (
                            <div className='form-group d-flex' key={ageG.camplistid}>
                                <div>
                                    <div className="check_box_wrapper cm_dark_check">
                                        <b style={{ minWidth: '200px', display: 'inline-block' }}>Age Group: {ageG.age}</b>
                                        <input
                                            onChange={() => { setcamplistid(ageG.camplistid); setcamplistidErr('') }}
                                            checked={ageG.camplistid === camplistid}
                                            type="radio"
                                            name="ageG" />
                                        <span className="check_box"><span></span></span>
                                    </div>
                                </div>

                                <div className='age_g_days'>
                                    {ageG?.days.map((dd, i) => (<div key={i}>
                                        <span>{dd.date} From <b>{dd.starttime}</b> to <b>{dd.endtime}</b></span>
                                    </div>))}
                                </div>




                            </div>
                        )) : ''
                    }

                    {camplistidErr ? <div className='cm_err text-center'>{camplistidErr}</div> : ''}

                    {
                        additionalExperience.length > 0 ? (
                            <Fragment>
                                <label>Additional Experiences</label>
                                <ul className="campers_add_exp">
                                    {
                                        additionalExperience.map((item, index) => (
                                            <li key={index} id={item._id}>
                                                <div style={{ color: item.color }}>
                                                    <p><div><b>Title</b></div> {getHtml(item.title)}</p>
                                                    <p><b>Price</b>: ${item.price}</p>
                                                    <p><div><b>Description </b></div> {getHtml(item.description)}</p>
                                                    <div className="quantity mb-3">
                                                        <button onClick={() => handleAdditionalExperienceQuantity(index, 0)} className="inc" />
                                                        <b>{item.quantity || 0}</b>
                                                        <button onClick={() => handleAdditionalExperienceQuantity(index, 1)} className="dec" />
                                                    </div>

                                                    <div className="check_box_wrapper ms-auto">
                                                        <input checked={item.quantity ? true : false} onChange={() => handleAdditionalExperienceChecked(index)} type="checkbox" />
                                                        <span className="check_box"><span /></span>
                                                    </div>
                                                </div>



                                                {
                                                    (item.quantity && item.question) ? (
                                                        <Fragment>
                                                            <div className="form-group mb-3 mt-3">
                                                                {item.question?.title ? <p className='m-0'>Q. {getHtml(item.question.title)}</p> : ''}
                                                                {item.question.subTitle ? <p><small>{getHtml(item.question.subTitle)}</small></p> : ''}
                                                            </div>

                                                            {
                                                                item.question.questionType === 1 ? (
                                                                    <Fragment>
                                                                        <textarea
                                                                            value={item?.question?.answer}
                                                                            onChange={e => handleAdditionalExperienceAnsChange(e.target.value, index)}
                                                                            className="form-control" />
                                                                    </Fragment>
                                                                ) : ''
                                                            }


                                                            {
                                                                item.question.questionType === 2 ? (
                                                                    <Fragment>
                                                                        <Select
                                                                            className="form-control"
                                                                            placeholder="Select"
                                                                            onChange={e => handleAdditionalExperienceAnsChange(e.value, index)}
                                                                            classNamePrefix="cm_select"
                                                                            options={item.question.options.map(im => ({ label: im.opt, value: im.opt }))} />
                                                                    </Fragment>
                                                                ) : ''
                                                            }


                                                            {
                                                                item.question.questionType === 4 ? (
                                                                    <Fragment>
                                                                        {item.question.options.length ? item.question.options.map((im, ind) => (
                                                                            <div className="check_box_wrapper cm_dark_check cm_dark_check_2" key={ind}>
                                                                                <input
                                                                                    checked={im.opt === item.question.answer ? true : false}
                                                                                    onChange={() => handleAdditionalExperienceAnsCheckboxChange(im.opt, index)}
                                                                                    type="checkbox" />
                                                                                <span className="check_box"><span></span></span>
                                                                                <p>{im.opt}</p>
                                                                            </div>
                                                                        )) : ''}

                                                                        {
                                                                            item?.question?.isOtherOption === 1 ? (
                                                                                <div className="check_box_wrapper cm_dark_check cm_dark_check_2">
                                                                                    <input
                                                                                        checked={item?.question?.isOtherOptionSelected ? true : false}
                                                                                        onChange={() => handleIsOtherOptionClick(index)}
                                                                                        type="checkbox" />
                                                                                    <span className="check_box"><span></span></span>
                                                                                    <p>Other</p>
                                                                                </div>
                                                                            ) : ''
                                                                        }

                                                                    </Fragment>
                                                                ) : ''
                                                            }


                                                            {
                                                                item?.question?.isOtherOptionSelected ? (
                                                                    <div className='pt-3 ps-4'>
                                                                        <textarea
                                                                            value={item?.question?.answer}
                                                                            onChange={e => handleAdditionalExperienceAnsChange(e.target.value, index)}
                                                                            className="form-control" />
                                                                    </div>
                                                                ) : ''
                                                            }

                                                            {
                                                                item?.question?.answerErr ? <h6 className='cm_err'>{item.question.answerErr}</h6> : ''
                                                            }

                                                        </Fragment>
                                                    ) : ''

                                                }

                                            </li>
                                        ))
                                    }
                                </ul>
                            </Fragment>
                        ) : ''

                    }

                    {campRelatedProducts.length > 0 &&
                        <Fragment>
                            <label>Camp Related Products</label>
                            <Slider {...settings2} className='product_slider'>
                                {
                                    campRelatedProducts.map((item, i) => (
                                        <div className='product_card product_card_2' key={i}>
                                            {
                                                item?.image?.length ? (
                                                    <Slider {...settings}>
                                                        {item?.image.map(img => (
                                                            <img src={img} className="img-fluid pointer" onClick={() => setViewProduct({ ...item, count: 1 })} />
                                                        ))}
                                                    </Slider>
                                                ) : ''
                                            }

                                            <div className='product_text'>
                                                <div className='pro_card_price'>
                                                    <p>${item?.price?.toFixed(2)}</p>
                                                </div>
                                                <h3 style={{ color: item.textColor || '#000' }}>{item.title}</h3>
                                                <span className={`btn btn-${item?.isAdded ? 'primary' : 'secondary'} w-100'`} onClick={() => handleProductAction(item, cartProgramData._id)}> 
                                                    {!item?.isAdded ?'View' : 'Remove'}
                                                </span>
                                            </div>

                                        </div>
                                    ))
                                }
                            </Slider>
                        </Fragment>
                    }
                    {questions?.length > 0 &&
                        <QuestionsComponent
                            questions={questions}
                            setQuestions={setQuestions}
                            source={1}
                        />
                    }
                    <div className="col-md-12">
                        <div className="row">

                            {
                                cartProgramData.is_lacrose === 1 ?
                                    <LacroseCheck
                                        camperId={currentCamper._id}
                                        programEndDate={cartProgramData.endDate}
                                        USLResponseSuccess={USLResponseSuccess}
                                        setUSLResponseSuccess={handleUSLSuccessResponse}
                                        Email={props?.userProfile?.email}
                                    />
                                    : ''
                            }

                            {
                                cartProgramData?.isParentAthlete === 1 ? <div className="col-md-12 mb-3 mt-3 cm_waiver">
                                    <label className="concussion">
                                        <span>Parent/Athlete Concussion Form<PDFIcon /></span>
                                        <div className='text-right'>
                                            {concussion ? <span className="updated_icon"><img src={require('../../../assets/img/check_icon.png')} /> Updated</span> : <span onClick={() => setActiveForm({ type: 'concussion', url: require('../../../assets/pdf/concussion-protocol.pdf') })} className="btn btn-secondary btn-sm">View</span>}
                                            {concussionErr ? <h6 className='cm_err'>{concussionErr}</h6> : ''}
                                        </div>
                                    </label>
                                </div> : ''
                            }





                            {
                                cartProgramData?.is_waivers === 1 ?
                                    <div className="col-md-12 mb-3 mt-3 cm_waiver">
                                        <ul>
                                            <li>
                                                <label>TCL Waiver</label>
                                                {waiverURL ? <span className="updated_icon"><img src={require('../../../assets/img/check_icon.png')} /> Updated</span> : <span onClick={() => {setWaiverType(1); setWaiverModal(true); setWaiverURLError("")}} className="btn btn-secondary btn-sm">Fill From</span>}
                                            </li>
                                        </ul>
                                        {waiverURLError ? <h6 className='cm_err'>{waiverURLError}</h6> : ''}


                                    </div>
                                    :
                                    ''
                            }


                            {!isCommuter && cartProgramData?.is_keydrom === 1 ?
                                <div className="col-md-12 mb-3 cm_waiver">

                                    <label className="concussion">
                                        <span>Key Deposit <PDFIcon /></span>
                                        <div className='text-right'>
                                            {keyDeposit ? <span className="updated_icon"><img src={require('../../../assets/img/check_icon.png')} alt=""/> Updated</span> : <span onClick={() => setActiveForm({ type: 'keyDeposit', url: require('../../../assets/pdf/key-deposit.pdf') })} className="btn btn-secondary btn-sm">View</span>}
                                            {keyDepositErr ? <h6 className='cm_err'>{keyDepositErr}</h6> : ''}
                                        </div>
                                    </label>
                                </div>
                                :
                                ''}


                        </div>
                    </div>
                    {USLMemberIDErr && <p className='text-danger ms-3'>{USLMemberIDErr}</p>}

                    {/* //TODO: Here we need to add signature and other items */}
                    <ConfirmDetails
                        cartId={props.cartId}
                        handleCamperSubmit={handleCamperSubmit}
                        closeSelectingView={closeSelectingView}
                        reload={props.reload}
                    />
                    {/* <div className="col-sm-12 text-center mb-5">
                        <button onClick={closeSelectingView} className="btn btn-light rounded-0 me-2" style={{ width: '130px' }}>Cancel</button>
                        <button onClick={handleCamperSubmit} className="btn btn-primary rounded-0" style={{ width: '130px' }}>Done</button>
                    </div> */}

                </div>
            </div>
            <WaiverPopups
                waiverType={waiverType}
                waiverURLAction={waiverURLAction}
                waiverModal={waiverModal}
                waiverModalAction={() => { setWaiverType(''); setWaiverModal(false) }} />


            <Modal
                show={alertModal}
                animation={false}
                dialogClassName="folder_model"
                size="lg"
                centered
            >
                <Modal.Body>
                    <span className="modal_close" onClick={() => setAlertModal(false)}><i className="icon-cross" /></span>
                    <div className="text-center">
                        <h3>Alert!</h3>
                        <p>Please increase the additional experience count.</p>
                    </div>
                </Modal.Body>
            </Modal>



            <Modal
                dialogClassName="h_auto"
                show={viewProduct._id}
                animation={false}
                size="xl"
                centered>
                <Modal.Body>
                    <div className="p-2 py-5 product_moda input_err_pos">
                        <span className="modal_close" onClick={handleViewClose}><i className="icon-cross" /></span>

                        <div className='row'>
                            <div className='col-lg-5'>
                                {
                                    viewProduct?.image?.length ? <Slider {...settings}>
                                        {viewProduct?.image.map(img => (
                                            <img src={img} className="img-fluid" />
                                        ))}
                                    </Slider> : ''
                                }


                            </div>
                            <div className='col-lg-7'>

                                <h3 style={{ color: viewProduct.textColor || '#000' }}>{viewProduct.title}</h3>



                                {props?.userProfile?.UserType === 1 ? <Fragment>


                                    <div className='d-flex justify-content-between align-items-center'>
                                        {getSizes(viewProduct.sizes)?.length ? <div className="form-group w-100 me-2">
                                            <label>Size</label>
                                            <Select
                                                className={`form-control ${viewProduct.selectSizeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Size"
                                                classNamePrefix="cm_select"
                                                value={{ label: viewProduct.selectSize, value: viewProduct.selectSize }}
                                                onChange={handleSizeChange}
                                                options={getSizes(viewProduct.sizes)} />


                                            {viewProduct.selectSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{viewProduct.selectSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div> : ''}


                                        {
                                            getColors2(viewProduct.sizes, viewProduct.selectSize)?.length ? <div className={`form-group w-100 ${getSizes(viewProduct.sizes)?.length ? 'ms-2' : ''}`}>
                                                <label>Color</label>
                                                <Select
                                                    isDisabled={(getSizes(viewProduct.sizes).length && !viewProduct.selectSize) ? true : false}
                                                    className={`form-control ${viewProduct.selectColorErr ? 'is-invalid' : ''}`}
                                                    placeholder="Select Color"
                                                    classNamePrefix="cm_select"
                                                    value={{ label: viewProduct.selectColor, value: viewProduct.selectColor }}
                                                    onChange={handleColorChange}
                                                    options={getColors2(viewProduct.sizes, viewProduct.selectSize)} />

                                                {viewProduct.selectColorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{viewProduct.selectColorErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                            </div> : ''
                                        }



                                    </div>


                                    <div className="quantity mb-4 mt-0">
                                        <button className="inc" onClick={() => handleStoreCount('remove')}></button>
                                        <b>{viewProduct.count}</b>
                                        <button className="dec" onClick={() => handleStoreCount('add')}></button>
                                    </div>

                                </Fragment> : ''}
                                {
                                    (viewProduct && viewProduct?.subItems?.length > 0) &&
                                    <>
                                        <h4>Add-ons</h4>
                                        <div className="d-flex flex-column flex-sm-row flex-wrap align-content-start align-items-stretch mb-3">
                                            {
                                                viewProduct?.subItems?.map(item => <Addon key={item._id} item={item} updateAddons={addAddonsToList} />)
                                            }
                                        </div>

                                    </>
                                }




                                <div className='d-flex justify-content-between align-items-center mb-3'>

                                    <div className="">
                                        <button onClick={addProductToCart} className="btn btn-primary btn-sm">Add to Cart</button>

                                    </div>

                                    <div className="product_price">
                                        <h2><b>Total Price: ${getTotalPrice()}</b></h2>
                                    </div>
                                </div>

                                <div className=''>
                                    {getHtml(viewProduct.desc)}
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                dialogClassName="h_auto"
                show={updatesSizeModal}
                animation={false}
                centered
            >
                <Modal.Body>
                    <span className="modal_close" onClick={() => {setGradeAndSize();setUpdatesSizeModal(false)}}><i className="icon-cross" /></span>
                    <div className="p-3">
                        <h5 className="text-center mb-4">Update T-shirt size & Grade</h5>
                        <div className="form-group">
                            <label>T-shirt Size</label>
                            <Select
                                className="form-control"
                                placeholder="Select"
                                value={tshirtSize}
                                onChange={e => setTshirtSize(e)}
                                classNamePrefix="cm_select"
                                options={shortsAndTshirtSizes.map(item => ({ label: item, value: item }))} />
                        </div>

                        {/* <div className="form-group">
                        <label>Shorts Size</label>
                        <Select
                            className="form-control"
                            placeholder="Select"
                            value={shortsSize}
                            onChange={e => setShortsSize(e)}
                            classNamePrefix="cm_select"
                            options={shortsAndTshirtSizes.map(item => ({label: item, value: item}))} />
                    </div> */}

                        <div className="form-group">
                            <label>Select Current Grade As of Today</label>
                            <Select
                                className="form-control"
                                placeholder="Select"
                                classNamePrefix="cm_select"
                                value={currentGrade}
                                onChange={e => setCurrentGrade(e)}
                                options={gradeArr.map(item => ({ label: item, value: item }))} />
                        </div>

                        <div className='d-flex justify-content-between'>
                            <span onClick={() => {setGradeAndSize(); setUpdatesSizeModal(false) }} className='btn btn-dark ps-5 pe-5'>Cancel</span>
                            <span className='btn btn-primary ps-5 pe-5' onClick={updateSingleCamper}>Update</span>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                show={activeForm.type}
                centered
                size='xl'
                dialogClassName="remove_header_line">

                <Modal.Header>
                    <span className="modal_close" onClick={() => setActiveForm({})}><i className="icon-cross" /></span>
                </Modal.Header>
                <Modal.Body>
                    <div className='embed_div'>
                        <PdfViewer fileUrl={activeForm.url} />
                    </div>


                    <div className="d-flex mt-3">
                        <button onClick={() => setActiveForm({})} className="btn btn-dark w-100">Cancel</button>
                        <button onClick={updateBookingKey} className="btn btn-primary w-100 ms-3">I Agree</button>
                    </div>

                </Modal.Body>
            </Modal>

        </div>
    )
}




const mapStateToProps = state => {
    let userProfile = state?.user?.userProfile;
    return {
        userProfile,
    };
}

export default connect(mapStateToProps)(CurrentParticipentComponent);