import { development, prod, local} from './env';

export default (() => {
  console.log(process.env.REACT_APP_ENV, '------REACT_APP_ENV-----')
  switch (process.env.REACT_APP_ENV) {

    case 'dev':
    case 'dev ':
    case 'development':
      return development;
    case 'prod':
    case 'prod ':
    case 'production':
      return prod;

    case 'local':
    case 'local ':
      return local;

    default:
      return local;
  }
})()